<template>
  <div class="app-container payment-record-component">
    <div class="filter-line">
      <span>
        <el-button @click="getTableList" icon="el-icon-refresh-right">{{
          this.$t("commons.refresh")
        }}</el-button>
        <el-button type="primary" @click="exportHandler">{{
          this.$t("commons.export")
        }}</el-button>
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        <i class="iconfont icon-loudoutu"></i>
        筛选
      </div>
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <template v-slot:invoiceBillNoSlot="row">
          <span
            :class="row.datas && 'link-span'"
            @click="jumpToInvoiceList(row.datas.invoiceBillNo)"
          >
            {{ row.datas.invoiceBillNo || "-" }}
          </span>
        </template>
      </finalTable>
    </div>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import {
  getPaymentList,
  paymentListExport,
  editInvoiceStatus,
} from "@/api/ruge/gsPark/customerService/paymentRecord";
import { dateFormat } from "@/filters/index";
export default {
  name: "invoiceRecordComponent",
  components: {
    finalTable,
  },
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          { prop: "payDate", label: "支付时间", width: "" },
          { prop: "orderType", label: "服务类型", width: "" },
          { prop: "parkLotCode", label: "所属停车场", width: "" },
          { prop: "customerOrderId", label: "服务单号", width: "" },
          { prop: "plate", label: "车牌号", width: "" },
          { prop: "status", label: "支付状态", width: "" },
          { prop: "invoiceStatus", label: "是否已开发票", width: "" },
          { prop: "payMoney", label: "交易金额", width: "" },
          { prop: "invoiceBillNo", label: "开票记录编号", width: "" },
          { prop: "transactionId", label: "微信支付编号", width: "" },
          { prop: "invoiceDate", label: "开票时间", width: "" },
          { prop: "operation", label: "操作", width: "115" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          orderType: {
            type: "selector",
            label: "服务类型",
            actionType: "goSearch",
            value: "",
            placeholder: "请输入服务类型",
            optionList: [
              {
                label: "停车缴费",
                value: "parkPay",
              },
              {
                label: "月卡缴费",
                value: "monthPay",
              },
            ],
          },
          parkLotCode: {
            type: "selector",
            label: "所属停车场",
            actionType: "goSearch",
            value: "",
            placeholder: "请输入服务类型",
            optionList: [
              {
                label: "锦绣一期停车场",
                value: "GS1",
              },
              {
                label: "锦绣二期停车场",
                value: "GS2",
              },
              {
                label: "锦绣三期停车场",
                value: "GS3",
              },
            ],
          },
          customerOrderId: {
            type: "input",
            label: "服务单号",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入服务单号",
            prefixIcon: "el-icon-search",
          },
          plate: {
            type: "input",
            label: "车牌号",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入车牌号",
            prefixIcon: "el-icon-search",
          },
          status: {
            type: "selector",
            label: "支付状态",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择支付状态",
            optionList: [
              {
                label: "待支付",
                value: "待支付",
              },
              {
                label: "已支付",
                value: "已支付",
              },
              {
                label: "支付失败",
                value: "支付失败",
              },
            ],
          },
          invoiceStatus: {
            type: "selector",
            label: "是否已开发票",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择是否已开发票",
            optionList: [
              {
                label: "是",
                value: "SUCCESS",
              },
              {
                label: "否",
                value: "WAIT",
              },
            ],
          },
          payMoney: {
            type: "numberInput",
            label: "交易金额",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入交易金额",
            prefixIcon: "el-icon-search",
          },
          invoiceBillNo: {
            type: "input",
            label: "开票记录编号",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入开票记录编号",
            prefixIcon: "el-icon-search",
          },
          transactionId: {
            type: "input",
            label: "微信支付编号",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入微信支付编号",
            prefixIcon: "el-icon-search",
          },

          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          invoiceBillNo: {
            type: "slot",
            slotName: "invoiceBillNoSlot",
          },
          orderType: {
            type: "enumerationColumn",
            emuList: {
              monthPay: "月卡缴费",
              parkPay: "停车缴费",
            },
          },
          payDate: {
            type: "dateFormat",
          },
          invoiceDate: {
            type: "dateFormat",
          },
          // invoiceStatus: {
          //   type: "enumerationColumn",
          //   emuList: {
          //     SUCCESS: "是",
          //     WAIT: "否",
          //   },
          // },
          invoiceStatus: {
            type: "switch",
            actionType: "switchEvent",
          },
          operation: {
            type: "icon",
            iconList: [],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          payDate: {
            type: "dateRange",
            pickerType: "date",
            label: "支付时间",
            value: [],
          },
          invoiceDate: {
            type: "dateRange",
            pickerType: "date",
            label: "开票时间",
            value: [],
          },
          orderType: {
            inline: true,
            value: "",
          },
          customerOrderId: {
            inline: true,
            value: "",
          },
          plate: {
            inline: true,
            value: "",
          },
          status: {
            inline: true,
            value: "",
          },
          invoiceStatus: {
            inline: true,
            value: "",
          },
          payMoney: {
            inline: true,
            value: "",
          },
          invoiceBillNo: {
            inline: true,
            value: "",
          },
          transactionId: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      requestParams: {
        current: 1,
        rowCount: 10,
        startDate: null,
        endDate: null,
        customerType: null,
        customerOrderId: null,
        invoiceStatus: null,
        payMoney: null,
        invoiceBillNo: null,
        transactionId: null,
        invoiceStartDate: null,
        invoiceEndDate: null,
      },
      loadingFlag: false,
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    if (this.$route.query.goBackFlag) {
      const curSearch = sessionStorage.getItem("payment_list_query_params");
      if (curSearch) {
        const tempObj = JSON.parse(curSearch);
        this.requestParams = { ...this.requestParams, ...tempObj };
        Object.keys(this.requestParams).forEach((item) => {
          if (this.dataset.searchLineConfig[item]) {
            this.dataset.searchLineConfig[item].value =
              this.requestParams[item];
          }
        });
      }
    } else {
      sessionStorage.removeItem("payment_list_query_params");
    }
    this.getTableList();
  },
  methods: {
    jumpToInvoiceList(invoiceNo) {
      if (!invoiceNo) return;
      sessionStorage.setItem(
        "invoice_list_query_params",
        JSON.stringify({
          billNo: invoiceNo,
        })
      );
      this.$router.push({
        path: "/gsPark/paymentInvoice/invoiceRecord",
        query: { goBackFlag: true },
      });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.requestParams.current = 1;
        this.dataset.pageVO.current = 1;
        this.dealTime(datas);
        this.ballotsTime(datas);
        this.requestParams = { ...this.requestParams, ...datas.params };
        this.getTableList();
      } else if (datas.type === "switchEvent") {
        this.updateEnableStatus(datas.row);
      } else if (datas.type === "paginationChange") {
        this.requestParams.current = datas.params.current.page;
        this.requestParams.rowCount = datas.params.current.limit;
        this.getTableList();
      }
    },
    updateEnableStatus({ customerOrderId, invoiceStatus }) {
      const emuMap = {
        ENABLE: "SUCCESS",
        DISABLE: "WAIT",
      };
      invoiceStatus;
      editInvoiceStatus({
        customerOrderId,
        invoiceStatus: emuMap[invoiceStatus],
      }).then((res) => {
        if (res) {
          this.$message.success("修改状态成功！");
          this.getTableList();
        }
      });
    },
    dealTime(datas) {
      if (datas.params.payDate && datas.params.payDate.length > 0) {
        this.requestParams.startDate = datas.params.payDate[0]
          ? dateFormat(datas.params.payDate[0], "YYYY-MM-DD HH:mm:ss")
          : null;
        this.requestParams.endDate = datas.params.payDate[1]
          ? dateFormat(
              new Date(datas.params.payDate[1]).getTime() +
                1000 * 60 * 60 * 24 -
                1,
              "YYYY-MM-DD HH:mm:ss"
            )
          : null;
        delete datas.params.payDate;
      }
      // else {
      //   this.requestParams.startDate = null;
      //   this.requestParams.endDate = null;
      // }
      return datas;
    },
    ballotsTime(datas) {
      if (datas.params.invoiceDate && datas.params.invoiceDate.length > 0) {
        this.requestParams.invoiceStartDate = datas.params.invoiceDate[0]
          ? dateFormat(datas.params.invoiceDate[0], "YYYY-MM-DD HH:mm:ss")
          : null;
        this.requestParams.invoiceEndDate = datas.params.invoiceDate[1]
          ? dateFormat(
              new Date(datas.params.invoiceDate[1]).getTime() +
                1000 * 60 * 60 * 24 -
                1,
              "YYYY-MM-DD HH:mm:ss"
            )
          : null;
        delete datas.params.invoiceDate;
      }
      // else {
      //   this.requestParams.invoiceStartDate = null;
      //   this.requestParams.invoiceEndDate = null;
      // }
      return datas;
    },
    getTableList() {
      this.loadingFlag = true;
      getPaymentList(this.requestParams)
        .then((res) => {
          res.rows.forEach((item) => {
            item.invoiceStatus =
              item.invoiceStatus === "SUCCESS" ? "ENABLE" : "DISABLE";
            if (item.parkLotCode == "GS1") {
              item.parkLotCode = "锦绣一期停车场";
            } else if (item.parkLotCode == "GS2") {
              item.parkLotCode = "锦绣二期停车场";
            } else if (item.parkLotCode == "GS3") {
              item.parkLotCode = "锦绣三期停车场";
            }
          });
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .catch(() => {
          this.dataset.tableData = [];
          this.dataset.pageVO.total = 0;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    exportHandler() {
      paymentListExport(this.requestParams).then((taskId) => {
        this.$message({
          type: "success",
          message: this.$t("message.operationSuccess"),
        });
        let exportObj = {
          taskId,
          taskName: "停车缴费记录",
          taskStatus: 0,
          rootPath: "customerWXPath",
        };
        this.$store.dispatch("PushExportNotice", exportObj);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.payment-record-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }
    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
  }
  .link-span {
    cursor: pointer;
    color: #409eff;
  }
}
</style>